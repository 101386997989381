import VueRouter from "vue-router";
import NProgress from "nprogress"; // 路由跳转时，出现在页面顶部的进度条。

const router = new VueRouter({
	mode: "history",
	routes: [
		{
			path: "/",
			name: "index",
			// component: () => import("@/pages/index/"), // 展示给外部用户的首页。
			redirect: {
				name: 'listIndex'
			}
		},
		/*
		 * @Description: 登录后布局的页面
		 * @Author: 张宇童
		 * @Date: 2020/9/6 13:23
		 */
		{
			path: "/admin",
			name: "admin_home",
			component: () => import("@/pages/layout/index"), // 登录后的页面 Layout.
			// redirect: { name: "admin_index" },
			children: [
				{
					path: 'list', // 收银清单
					name: 'listIndex',
					component: () => import('@/pages/admin/list'),
				},
				{
					path: 'BoutiqueList', // 精品列表
					name: 'BoutiqueList',
					component: () => import(/*webpackChunkName: "BoutiqueList"*/'@/pages/admin/boutique/list')

				},
				{
					path: 'stockList', // 库存列表,
					name: 'stockList',
					component: () => import(/*webpackChunkName: 'stockList'*/'@/pages/admin/stock/list')
				},
				{
					path: 'InsuranceInput', // 保险业务
					name: 'InsuranceInput',
					component: () => import(/*webpackChunkName: 'InsuranceList'*/ '@/pages/admin/insurance/input/')
				}
			],
		},
		{
			path: '/*',
			component: () => import('@/pages/layout/index'),
			children: [
				{
					path: '*',
					component: () =>  import('@/pages/error/404/')
				}
			]
		}
	],
});

// 路由开始跳转时，加载进度条。路由跳转完成时，隐藏进度条。
router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});
export default router;
