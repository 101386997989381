import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import VueRouter from "vue-router";
import "@/assets/iconfont/iconfont.css";
import message from "@/components/message/index";

// 引入全局 css. 例如，在该文件中指定 html 的字体大小为 10px, 此大小为整个应用的基础字体。
// vue 页面中需要指定字体大小的地方，使用 0.8rem, 1.2rem 这种方式, 而不是使用 8px, 12px.
import "@/index.css";
// chenxi 20200915
// 启用 SlimScroll. 请注意这个 SlimScroll 是我从 github 上下载以后，修改了少量源代码。
// 请查看该文件内的注释。
import VueSlimScroll from "@/utils/vue-slimscroll.js";

Vue.prototype.$message = message;
Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.use(VueSlimScroll);

new Vue({
	vuetify,
	router,
	render: (h) => h(App),
}).$mount("#app");
